<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0 justify-content-center auth-bg">
      <!-- Brand logo-->
      <!-- <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">My Munshi</h2>
      </b-link> -->
      <!-- /Brand logo-->

      <b-col
        class="d-flex justify-content-center auth-bg px-2 p-5"
        align-self="center"
      >
        <b-overlay
          :show="loginLoading"
          rounded="sm"
          variant="transparent"
          opacity="0.5"
          blur="2px"
        >
          <b-col lg="12" class="px-xl-2 mx-auto">
            <b-card>
              <p class="text-center">
                <b-img
                  height="100px"
                  :src="require('@/assets/images/logo/logo_light.png')"
                />
              </p>
              <b-card-title
                class="mb-1 font-weight-bold text-center"
                title-tag="h2"
              >
                Welcome to My Munshi!
              </b-card-title>
              <b-card-text class="mb-2 text-center">
                Please enter bellow your login credentials to verify your
                account!
              </b-card-text>

              <!-- form -->
              <validation-observer ref="loginForm" #default="{ invalid }">
                <b-form class="auth-login-form mt-2" @submit.prevent="login">
                  <!-- email -->

                  <b-form-group label="Email" label-for="login-email">
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      vid="email"
                    >
                      <b-form-input
                        id="login-email"
                        v-model="userEmail"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Email Address"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- forgot password -->
                  <b-form-group label="Password">
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      vid="password"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="login-password"
                          v-model="password"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          name="login-password"
                          placeholder="********"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group>
                    <b-form-checkbox v-model="isPolicySelected" value="true">
                      Continue to agree our&nbsp;<a
                        href="https://pages.mymunshi.in/services-terms"
                        target="_blank"
                        contenteditable="false"
                        style="font-size: 1rem; text-align: left"
                        ><u>Terms</u></a
                      >&nbsp;&&nbsp;<a
                        href="https://pages.mymunshi.in/privacy-policy"
                        target="_blank"
                        contenteditable="false"
                        style="font-size: 1rem; text-align: left"
                        ><u>Policy</u></a
                      >
                    </b-form-checkbox>
                  </b-form-group>

                  <div class="d-flex justify-content-end">
                    <b-link :to="{ name: 'auth-send-otp' }">
                      <small>Forgot Password?</small>
                    </b-link>
                  </div>
                  <!-- submit buttons -->
                  <b-button
                    type="submit"
                    variant="primary"
                    class="mt-2"
                    block
                    :disabled="invalid || loginLoading"
                  >
                    Sign in
                  </b-button>
                </b-form>
              </validation-observer>
            </b-card>
          </b-col>
        </b-overlay>
      </b-col>

      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BCard,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
  BOverlay
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import defaultConfig from '@/configs/defaultConfig'

export default {
  directives: {
    'b-tooltip': VBTooltip
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BCard,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    BOverlay
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      isPolicySelected: true,
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
      loginLoading: false
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    }
  },
  methods: {
    login() {
      localStorage.removeItem(defaultConfig.displayBoardKeyName)
      if (!this.isPolicySelected) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Warning`,
            icon: 'CoffeeIcon',
            variant: 'error',
            text: `Please accept our terms & policy!`
          }
        })
        return false
      }
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          this.loginLoading = true
          useJwt
            .login({
              email: this.userEmail,
              password: this.password
            })
            .then((response) => {
              console.log('LOGIN: ', response.data)
              this.loginLoading = false
              if (response.data.success) {
                const { userData } = response.data
                useJwt.setToken(response.data.accessToken)
                useJwt.setRefreshToken(response.data.refreshToken)
                localStorage.setItem('userData', JSON.stringify(userData))
                this.$ability.update(userData.ability)

                // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                this.$router
                  .replace(getHomeRouteForLoggedInUser(userData.role))
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: `Welcome ${
                          userData.fullName || userData.username
                        }`,
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`
                      }
                    })
                  })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Authentication Failed`,
                    icon: 'CoffeeIcon',
                    variant: 'error',
                    text: `Please enter valid email and password!`
                  }
                })
              }
            })
            .catch((error) => {
              // this.$refs.loginForm.setErrors(error)
              // console.log(error)
              this.loginLoading = false
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Sorry`,
                  icon: 'CoffeeIcon',
                  variant: 'error',
                  text: `Failed to login. Please try again!`
                }
              })
            })
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
